import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, Typography, Button, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import DiscreteSlider from "../ui/discrete-slider/discrete-slider.component";
import { Radar } from "react-chartjs-2";
import { getAverage } from "../../functions/insert-risk-assessment.helper";
import { options, graphWidth, graphHeight } from "../../constants/graph";
import { connect } from "react-redux";
import { handleModal } from "../../redux/actions";
import { RiskAreaApi } from "../../services/api.service";

const RiskArea = new RiskAreaApi()
const Step2 = ({
  disabled = false,
  handleSaveStep,
  handleBack,
  presidi,
  setPresidi,
  dispatch,
  area,
}) => {
  const {
    procedure,
    segregazione,
    deleghe,
    tracciabilita,
    monitoraggio,
  } = presidi;
  const valueList = [
    procedure,
    segregazione,
    deleghe,
    tracciabilita,
    monitoraggio,
  ];
  const data = {
    labels: [
      "Procedure",
      "Segregazione dei ruoli",
      "Deleghe",
      "Tracciabilità",
      "Monitoraggio",
    ],
    datasets: [
      {
        label: "PRESIDI",
        data: valueList,
        backgroundColor: ["rgba(116,186,248,0.5)"],
        borderColor: ["rgba(116,186,248,1)"],
      },
    ],
  };

  const [protocols, setProtocols] = useState([])

  const procedureProtocols = useMemo(() => {
    return protocols?.filter(protocol => protocol.procedure && protocol.attivo) ?? []
  }, [protocols])

  const segregazioneProtocols = useMemo(() => {
    return protocols?.filter(protocol => protocol.segregazione && protocol.attivo) ?? []
  }, [protocols])

  const delegheProtocols = useMemo(() => {
    return protocols?.filter(protocol => protocol.poteri && protocol.attivo) ?? []
  }, [protocols])

  const tracciabilitaProtocols = useMemo(() => {
    return protocols?.filter(protocol => protocol.tracciabilita && protocol.attivo) ?? []
  }, [protocols])

  const monitoraggioProtocols = useMemo(() => {
    return protocols?.filter(protocol => protocol.monitoraggio && protocol.attivo) ?? []
  }, [protocols])

  const getTypeValue = useCallback((protocols) => {
    if (!protocols?.length) return 1

    const atLeastOneOperative = protocols?.some(protocol => !!protocol.operativo) ?? false
    const atLeastOneFormalized = protocols?.some(protocol => !!protocol.formalizzato) ?? false

    if (atLeastOneOperative && atLeastOneFormalized) return 3
    if (atLeastOneOperative || atLeastOneFormalized) return 2

    return 1
  }, [])

  const procedureValue = useMemo(() => {
    return getTypeValue(procedureProtocols)
  }, [procedureProtocols])

  const segregazioneValue = useMemo(() => {
    return getTypeValue(segregazioneProtocols)
  }, [segregazioneProtocols])

  const delegheValue = useMemo(() => {
    return getTypeValue(delegheProtocols)
  }, [delegheProtocols])

  const tracciabilitaValue = useMemo(() => {
    return getTypeValue(tracciabilitaProtocols)
  }, [tracciabilitaProtocols])

  const monitoraggioValue = useMemo(() => {
    return getTypeValue(monitoraggioProtocols)
  }, [monitoraggioProtocols])

  const handleProposta = useCallback(() => {
    return dispatch(handleModal({
      open: true,
      maxWidth: 'sm',
      title: 'Proposta Risk Assessment',
      content: <>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>IMPATTO</TableCell>
                  <TableCell>Valore proposto</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Procedure</TableCell>
                  <TableCell>{procedureValue}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Segregazione</TableCell>
                  <TableCell>{segregazioneValue}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Deleghe</TableCell>
                  <TableCell>{delegheValue}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tracciabilità</TableCell>
                  <TableCell>{tracciabilitaValue}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Monitoraggio</TableCell>
                  <TableCell>{monitoraggioValue}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={2}>
            <Button
              style={{ fontWeight: "bold", marginTop: 20 }}
              onClick={() => {
                setPresidi({
                  ...presidi,
                  procedure: procedureValue,
                  segregazione: segregazioneValue,
                  deleghe: delegheValue,
                  tracciabilita: tracciabilitaValue,
                  monitoraggio: monitoraggioValue,
                })
                dispatch(handleModal({ open: false }))
              }}
              color={"secondary"}
              variant={"contained"}
            >
              Applica
            </Button>
          </Grid>
          <Grid item xs={5}></Grid>
        </Grid>
      </>,
    }));
  }, [procedureValue, segregazioneValue, delegheValue, tracciabilitaValue, monitoraggioValue, protocols])

  useEffect(() => {
    if (!area) return

    const fetchData = async () => {
      const { data } = await RiskArea.getProtocols(area.uuid)
      setProtocols(data ?? [])
    }

    fetchData()
  }, [area])

  return (
    <Grid container className={"stepContainer"} xs={12}>
      <Grid container xs={12} className={"riskValutazioneContent"}>
        <Grid xs={12} item>
          <Typography variant={"subtitle1"}>
            Seleziona, oppure inserisci i valori 1 (inesistente), 2 (parziale), 3 (esistente)
          </Typography>
        </Grid>
        <Grid xs={12} md={6} style={{ marginTop: "2em" }} item>
          <DiscreteSlider
            max={3}
            disabled={disabled}
            value={procedure}
            setValue={(procedure) =>
              setPresidi({
                ...presidi,
                procedure,
              })
            }
            label={"Procedure"}
          />
          <DiscreteSlider
            max={3}
            disabled={disabled}
            value={segregazione}
            setValue={(segregazione) =>
              setPresidi({
                ...presidi,
                segregazione,
              })
            }
            label={"Segregazione"}
          />
          <DiscreteSlider
            max={3}
            disabled={disabled}
            value={deleghe}
            setValue={(deleghe) =>
              setPresidi({
                ...presidi,
                deleghe,
              })
            }
            label={"Deleghe"}
          />
          <DiscreteSlider
            max={3}
            disabled={disabled}
            value={tracciabilita}
            setValue={(tracciabilita) =>
              setPresidi({
                ...presidi,
                tracciabilita,
              })
            }
            label={"Tracciabilità"}
          />
          <DiscreteSlider
            max={3}
            disabled={disabled}
            value={monitoraggio}
            setValue={(monitoraggio) =>
              setPresidi({
                ...presidi,
                monitoraggio,
              })
            }
            label={"Monitoraggio"}
          />
        </Grid>
        <Grid xs={12} md={6} className={"graphContainer"} item>
          <Radar
            options={{
              ...options,
              scale: {
                ...options.scale,
                ticks: {
                  ...options.scale.ticks,
                  max: 3,
                },
              },
            }}
            data={data}
          />
        </Grid>
      </Grid>
      <Grid
        container
        className={"riskValutazioneFooter"}
        style={{ borderTop: "1px solid #AAAAAA", paddingTop: "2rem" }}
        xs={12}
      >
        <Grid container xs={6}>
          <Grid style={{ marginRight: 20 }} item>
            <Typography style={{ fontWeight: "bold" }} variant={"h6"}>
              VALORE MEDIO:
            </Typography>
          </Grid>
          <Grid item>
            {" "}
            <Typography
              style={{ fontWeight: "bold"}}
              variant={"h5"}
              color={"primary"}
            >
              {getAverage(valueList)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={6} style={{ justifyContent: "flex-end" }}>
          <Grid style={{ marginRight: 20 }} item>
            <Button
              style={{ fontWeight: "bold" }}
              onClick={handleBack}
              color={"primary"}
              variant={"outlined"}
            >
              Indietro
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{ fontWeight: "bold", marginRight: "15px" }}
              onClick={handleProposta}
              color={"success"}
              variant={"outlined"}
              disabled={disabled}
            >
              Proposta
            </Button>
            <Button
              style={{ fontWeight: "bold" }}
              onClick={handleSaveStep}
              color={"primary"}
              variant={"contained"}
            >
              {disabled ? "prosegui" : "Salva e prosegui"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(null)(Step2);
